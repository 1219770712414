import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const NoPhotoIcon =(props) => {

    return (
        <SvgIcon viewBox="0 0 30 30" {...props}>
            <path d="m21.167 12c-.402 0-.76.207-1.02.48l-5.993 6.296c-.26.29-.543.268-.792 0l-2.996-3.25c-.3-.324-.686-.52-1.107-.526-.422-.005-.814.205-1.115.543l-4.984 5.593c-.48.48.26 1.21.737.682l4.986-5.593c.355-.41.626-.154.776.018l2.996 3.25c.298.325.686.507 1.102.507s.8-.187 1.102-.506l5.993-6.297c.31-.317.618-.162.792 0l4.487 4.636c.47.498 1.197-.24.707-.718l-4.488-4.635c-.338-.35-.766-.48-1.185-.48zm-17.667 12h23c.277 0 .5.223.5.5s-.223.5-.5.5h-23c-.277 0-.5-.223-.5-.5s.223-.5.5-.5zm5-18c-1.375 0-2.5 1.125-2.5 2.5s1.125 2.5 2.5 2.5 2.5-1.125 2.5-2.5-1.125-2.5-2.5-2.5zm0 1c.834 0 1.5.666 1.5 1.5s-.666 1.5-1.5 1.5-1.5-.666-1.5-1.5.666-1.5 1.5-1.5zm-7-7c-.822 0-1.5.678-1.5 1.5v27c0 .822.678 1.5 1.5 1.5h27c.822 0 1.5-.678 1.5-1.5v-27c0-.822-.678-1.5-1.5-1.5zm0 1h27c.286 0 .5.214.5.5v27c0 .286-.214.5-.5.5h-27c-.286 0-.5-.214-.5-.5v-27c0-.286.214-.5.5-.5z"/>
        </SvgIcon>
    )
}