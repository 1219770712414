import React, {useContext, useEffect, useState, useRef} from "react";
import useGStyles from "../../assets/global-styles";
import useStyles from "./styles";
import ReService from "../../services/re.service.js";
import CustomSnackbar from "../../components/custom-snackbar/CustomSnackbar.jsx"
import fetchMoreData from "../../utils/fetchMoreData";
import ReItem from "./ReItem/ReItem.jsx"
import InfiniteScroll from 'react-infinite-scroll-component';
import clsx from "clsx";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import { useParams } from "react-router-dom";
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


let dateRequest = new Date();
let fetchMoreLoading = false;

const defaultViews = ["во двор", "на море", "на парк"];
const defautLayouts = [{label: "Студии", value: "studio"}, {label: "Евро 2к", value: "euro-2-rooms"}, {label: "Евро 3к", value: "euro-3-rooms"}];

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};




const ReItems = (props) => {

    const classes = useStyles();
    const gClasses = useGStyles();

    //получаем get-параметры из url
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const hideOldSteps = !!(params && params.onlyStep3);

    const [defaultData, setDefaultData] = useState([]);

    const [loading, setLoading] = useState(true);

    const [data, setData] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const [filter, setFilter] = useState({houses: [], areas: [], floors: [], views: [], steps: [3]});

    const [toast, setToast] = useState({open: false, severity: "error", "text": ""});


    const onChangeHouse = (event) => {
        const {
            target: { value },
        } = event;
        const f = {...filter, houses: (typeof value === 'string' ? value.split(',') : value)};
        setFilter(f);
        //fetchMoreData(true, f);
        fetchMoreData({reload: true, filter: f, fetchData: ReService.getItems, dateRequest, fetchMoreLoading, data, setData, hasMore, setHasMore, setToast, wrapperId: "scrollableDiv"})
    }

    const onChangeArea = (event) => {
        const {
            target: { value },
        } = event;
        const f = {...filter, areas: (typeof value === 'string' ? value.split(',') : value)};
        setFilter(f);
        //fetchMoreData(true, f);
        fetchMoreData({reload: true, filter: f, fetchData: ReService.getItems, dateRequest, fetchMoreLoading, data, setData, hasMore, setHasMore, setToast, wrapperId: "scrollableDiv"})
    }

    const onChangeFloor = (event) => {
        const {
            target: { value },
        } = event;
        const f = {...filter, floors: (typeof value === 'string' ? value.split(',') : value)};
        setFilter(f);
        //fetchMoreData(true, f);
        fetchMoreData({reload: true, filter: f, fetchData: ReService.getItems, dateRequest, fetchMoreLoading, data, setData, hasMore, setHasMore, setToast, wrapperId: "scrollableDiv"})
    }

    const onChangeViews = (event) => {
        const {
            target: { value },
        } = event;
        const f = {...filter, views: (typeof value === 'string' ? value.split(',') : value)};
        setFilter(f);
        //fetchMoreData(true, f);
        fetchMoreData({reload: true, filter: f, fetchData: ReService.getItems, dateRequest, fetchMoreLoading, data, setData, hasMore, setHasMore, setToast, wrapperId: "scrollableDiv"})
    }

    const reloadDefaultData = async (steps) => {

        const result = await ReService.getFilterData({steps});

        if (!(result && result.status && result.area && result.area.length) ) {
            return;
        }

        const tmpData = {
            areas: defautLayouts.concat(result.area.sort((a,b) => (a-b)).map(a => ({label: a+"м²", value: a}))),
            houses: result.houses.sort((a,b) => (+a.replace(/[^0-9]/gi,"") - +b.replace(/[^0-9]/gi,""))),
            floors: result.floors.sort((a,b) => (a-b)),
        };

        const f = {
            ...filter,
            steps: steps,
            areas: filter.areas.filter(a => tmpData.areas.indexOf(a) > -1),
            houses: filter.houses.filter(h => tmpData.houses.indexOf(h) > -1),
            floors: filter.floors.filter(f => tmpData.floors.indexOf(f) > -1),
        };

        setFilter(f);

        setDefaultData(tmpData);
        setLoading(false);

        //fetchMoreData(true, f);
        fetchMoreData({reload: true, filter: f, fetchData: ReService.getItems, dateRequest, fetchMoreLoading, data, setData, hasMore, setHasMore, setToast, wrapperId: "scrollableDiv"})


    }

    useEffect(() => {

        reloadDefaultData(filter.steps);

    },[]);

    if(loading){
        return (
            <div className={classes.kurMain} >
                <CircularProgress />
            </div>
        )
    }

    const onChangeStep = (event, step) => {
        let steps = filter.steps.filter(s => s != step);
        if(event.target.checked) steps.push(step);
        reloadDefaultData(steps);
    }

    return (
        <div className={classes.kurMain}>

            <div className={classes.kurFilterBar}>

                {
                    !hideOldSteps &&
                    <div className={classes.kurFilterBarCheckSteps}>
                        <FormControlLabel control={<Checkbox size="small" onChange={(e) =>onChangeStep(e, 1)} checked={filter.steps.indexOf(1) > -1} />} label="1 очередь" />
                        <FormControlLabel control={<Checkbox size="small" onChange={(e) =>onChangeStep(e, 2)} checked={filter.steps.indexOf(2) > -1} />} label="2 очередь" />
                        <FormControlLabel control={<Checkbox size="small" onChange={(e) =>onChangeStep(e, 3)} checked={filter.steps.indexOf(3) > -1} />} label="3 очередь" />
                    </div>
                }

                <FormControl sx={{m: 1, width: "calc(50% - 16px)" }} size="small">
                    <InputLabel id="house">Номера домов</InputLabel>
                    <Select
                        id="house"
                        multiple
                        value={filter.houses}
                        label="Дом"
                        onChange={onChangeHouse}
                        input={<OutlinedInput label="Укажите номера домов" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                    >
                        {defaultData.houses.map((h) => (
                            <MenuItem key={h} value={h} className={classes.menu}>
                                <Checkbox checked={filter.houses.indexOf(h) > -1} />
                                <ListItemText primary={"Дом №"+h} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{m: 1, width: "calc(50% - 16px)" }} size="small">
                    <InputLabel id="area">Планировки</InputLabel>
                    <Select
                        id="area"
                        multiple
                        value={filter.areas}
                        label="Планировка"
                        onChange={onChangeArea}
                        input={<OutlinedInput label="Укажите планировки" />}
                        renderValue={(selected) => (selected.map(s => defaultData.areas.find(d => d.value == s)?.label).join(', '))}
                        MenuProps={MenuProps}
                    >
                        {defaultData.areas.map((a) => (
                            <MenuItem key={a.value} value={a.value} className={classes.menu}>
                                <Checkbox checked={filter.areas.indexOf(a.value) > -1} />
                                <ListItemText primary={a.label} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{m: 1, width: "calc(50% - 16px)" }} size="small">
                    <InputLabel id="floor">Этажи</InputLabel>
                    <Select
                        id="floor"
                        multiple
                        value={filter.floors}
                        label="Этажи"
                        onChange={onChangeFloor}
                        input={<OutlinedInput label="Укажите этажи" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                    >
                        {defaultData.floors.map((f) => (
                            <MenuItem key={f} value={f} className={classes.menu}>
                                <Checkbox checked={filter.floors.indexOf(f) > -1} />
                                <ListItemText primary={f+" этаж"} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{m: 1, width: "calc(50% - 16px)" }} size="small">
                    <InputLabel id="view">Виды</InputLabel>
                    <Select
                        id="view"
                        multiple
                        value={filter.views}
                        label="Виды"
                        onChange={onChangeViews}
                        input={<OutlinedInput label="Укажите виды" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                    >
                        {defaultViews.map((f) => (
                            <MenuItem key={f} value={f} className={classes.menu}>
                                <Checkbox checked={filter.views.indexOf(f) > -1} />
                                <ListItemText primary={f} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

            </div>

            <div id="scrollableDiv" className={classes.scrollBoxRe} >

                <InfiniteScroll
                    dataLength={data.length}
                    scrollThreshold="700px"
                    next={() => fetchMoreData({reload: false, filter, fetchData: ReService.getItems, dateRequest, fetchMoreLoading, data, setData, hasMore, setHasMore, setToast, wrapperId: "scrollableDiv"})}
                    hasMore={hasMore}
                    //onScroll={() => onScroll()}
                    loader={<div className={gClasses.circularProgressWrapper}><CircularProgress/></div>}
                    scrollableTarget="messagesScrollableDiv"
                    endMessage={<div>Все данные загружены</div>}
                >
                    {data.map((item, index) => (
                        <ReItem
                            classes={classes}
                            key={item._id}
                            {...item}
                        />
                    ))}
                </InfiniteScroll>

            </div>


            <CustomSnackbar toast={toast} setToast={setToast} classes={classes}/>


        </div>
    );

}

export default ReItems;
