//import instance from "../api/axios.api";
import { $axios } from "../api/axios.api";

const ReService = {

    async getItems(options) {
        const { data } = await $axios.post("/re/get-items", {...options});
        return data;
    },

    async getFilterData(options) {
        const { data } = await $axios.post("/re/get-filter-data", {...options});
        return data;
    },

};
export default ReService;
