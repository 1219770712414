import React, {useContext} from "react";
import Router from "./router/Router";
import Layout from "./components/Layout";

const App = () => {

    return (
        <Router />
    );
};

export default App;
