import clsx from "clsx";
import {NoPhotoIcon} from "../../../assets/svg-icons";
import React from "react";
import {getTextView, priceToTriads} from "../../../utils/functions.js"

export default (props) => {

    const {area, house, floor, options, name, price, views, cover, classes} = props;

    return (
        <div className={classes.reItemWrapper}>

            <div className={classes.reItemHeader}>
                <p>Дом №{house}, {name}, {area}м², {floor} этаж, вид {views.map(v => getTextView(v)).join(", ")}{options?", "+options:""}</p>
                <p><span style={{fontWeight: 600, color: "#D39E6D"}}>Цена {priceToTriads(price)}р.</span><span style={{fontWeight: 100, color: "#bbbbbb", fontSize: 14}}> ({priceToTriads(Math.round(price/area))}р./м²)</span></p>
            </div>

            <div className={clsx(classes.cover, (!cover && classes.noPhoto))}>
                {cover? <img src={cover} />:<NoPhotoIcon className={classes.noPhotoIcon}/>}
            </div>

        </div>
    )

}