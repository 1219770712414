import React from "react";
import { Routes, Route } from "react-router-dom";
import ReItems from "../pages/ReItems/ReItems.jsx";

//загружает нужный компонент в зависимости от url
const Router = () => {

    return (
        <Routes>
            <Route path="/" element={<ReItems/>}/>
        </Routes>
        );
    };

export default Router;
